<template>
    <div class="speed">
        <h5header :name="$t('reward.desc171')" :right="false" />
        <div class="speedcon">
            <div class="speed-title">
                <div>{{ $t('reward.desc171') }}！</div>
                <p>{{ $t('reward.desc209') }}</p>
            </div>
            <div class="speed-name flex">
                <img src="../../public/images/new/inviteicon6.png" alt="">
                <div class="flex">{{ $t('market.desc188') }}</div>
                <img src="../../public/images/new/inviteicon5.png" alt="">
            </div>
            <div class="speed-wrappar">
                <div class="speed-desc" >{{ $t('reward.desc212') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc198') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc181') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc199') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc200') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc201') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc213') }}</div>
                   <!-- <div class="speed-desc">{{ $t('reward.desc220') }}</div> -->
                   <div class="speed-desc">{{ $t('reward.desc186') }}</div>
                   <div class="speed-desc"><label>{{ planetInfo.startTime }} - {{ planetInfo.endTime }} (GST)</label> </div>
                   <div class="speed-desc">{{ $t('reward.desc187') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc202') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc189') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc203') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc191') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc204') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc205') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc206') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc207') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc208') }}</div>
                   <div class="speed-desc">{{ $t('reward.desc219') }}</div>
        
            </div>
        </div>
    </div>
</template>

<script>
import h5header from "@/componets/h5header.vue";
export default {
    components: {
        h5header
    },
    data() {
        return {
            planetInfo: {}
        }
    },
    mounted() {
        this.$post(this.URL.reward.planetConfig, {
            type: 1
        }).then(res => {
            if (res.code == 0) {
                this.planetInfo = res.data;
            }
        })

    }
}
</script>

<style lang="less" scoped>
.speed {
    padding: 96px 0 180px;
    background: url('../../public/images/reward/planetbg1.png') top no-repeat;
    background-size: 100% auto;

    .speedcon {
        width: 1240px;
        margin: 0 auto;

        .speed-title {
            padding: 124px 0;
            font-size: 20px;
            color: #FFFFFF;
            line-height: 24px;

            div {
                margin-bottom: 32px;
                font-size: 56px;
                color: #C9FA5B;
                font-weight: 600;
                line-height: normal;
            }
        }

        .speed-name {
            margin-bottom: 48px;
            justify-content: center;

            img {
                flex: 0 0 95px;
                width: 95px;
                height: 17px;
                margin-top: 12px;
            }

            div {
                padding: 0 8px;
                font-size: 20px;
                color: #C9FA5B;
                font-weight: 600;
                line-height: 40px;

                img {
                    flex: 0 0 40px;
                    width: 40px;
                    height: 40px;
                    margin: 0 8px 0 0;
                }
            }
        }


        .speed-desc {
            margin-bottom: 24px;
            font-size: 16px;
            color: rgba(255, 255, 255, 0.80);
            label{
                color: #C9FA5B;
            }
        }

        .rate-table {
            width: 752px;
            margin-bottom: 24px;
            border: 1px solid rgba(255, 255, 255, 0.08);
            box-sizing: border-box;

            .table-thead {
                background: rgba(201, 250, 91, 0.10);

                div {
                    flex: 0 0 25%;
                    height: 39px;
                    font-size: 12px;
                    color: rgba(255, 255, 255, 0.85);
                    text-align: center;
                    border-right: 1px solid rgba(255, 255, 255, 0.08);

                    &:last-child {
                        border-right: 0;
                    }
                }
            }

            .tbody-info {
                border-top: 1px solid rgba(255, 255, 255, 0.08);

                div {
                    flex: 0 0 25%;
                    height: 39px;
                    font-size: 12px;
                    color: rgba(255, 255, 255, 0.85);
                    text-align: center;
                    border-right: 1px solid rgba(255, 255, 255, 0.08);
                    line-height: 39px;

                    &:last-child {
                        border-right: 0;
                    }
                }
            }
        }
    }
}

@media (max-width:1200px) {
    .speed {
        padding: 68px 16px 70px;

        .speedcon {
            width: 100%;

            .speed-title {
                padding: 100px 0 120px;
                font-size: 16px;

                div {
                    font-size: 28px;
                }
            }

            .rate-table {
                width: 100%;

                .table-thead {
                    background: rgba(201, 250, 91, 0.10);

                    div {
                        flex: 0 0 25%;
                        height: inherit;
                        padding: 12px 6px;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media (max-width:767px) {
    .speed {
        padding: 44px 16px;
        background: url('../../public/images/reward/speedbg3.png') top no-repeat;
        background-size: 100% auto;

        .speedcon {
            .speed-title {
                padding: 48px 0;
                font-size: 12px;
                line-height: 14px;

                div {
                    margin-bottom: 16px;
                    font-size: 20px;
                }
            }

            .speed-name {
                img {
                    margin-top: 1px;
                }

                div {
                    font-size: 20px;
                    line-height: 20px;

                    img {
                        flex: 0 0 20px;
                        width: 20px;
                        height: 20px;
                        margin: 0 4px 0 0;
                    }
                }
            }

            .speed-desc {
                margin-bottom: 16px;
                font-size: 14px;
            }
        }
    }
}</style>