<template>
    <div class="h5header">
        <img src="../public/images/new/backicon.png" alt="" class="return" @click="$router.go(-1)" />
        <div class="name flexcenter">{{ name }}</div>
        <div class="lang" v-if="right">
            <img src="../public/images/langicon.png" alt="" @click="dialogBackset = true" />
        </div>

        <van-popup v-model:show="dialogBackset" position="bottom" round>
            <div class="lang-info">
                <div class="lang-title">{{ $t('sign.desc36') }} <img src="../public/images/closemodal.png" alt=""
                        @click="dialogBackset = false" /></div>
                <div class="lang-item flex" v-for="item in langList" :key="item.id" @click="handleCommand(item.code)">
                    <div>{{ item.name }}</div>
                    <img :src="item.code == lang ? require('../public/images/new/checkimg.png') : require('../public/images/new/checkimg1.png')"
                        alt="" />
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
export default {

    
    props: ['name', 'right'],
    data() {
        return {
            dialogBackset: false,
            langList: [],
            lang: ''
        }
    },
    mounted() {
        this.lang = localStorage.getItem('language');
        if (localStorage.getItem('lang') == null) {
            this.$post(this.URL.home.lang, {}).then(res => {
                if (res.code == 0) {
                    this.langList = res.data;
                    localStorage.setItem('lang', JSON.stringify(res.data))
                } else {
                    this.$message.error(res.message)
                }
            })
        } else {
            this.langList = JSON.parse(localStorage.getItem('lang'))
        }
    },
    methods: {
        handleCommand(val) {

            localStorage.setItem('language', val);
            this.dialogBackset = false;
            setTimeout(() => {
                location.reload();
            }, 600);
        },
    }
}
</script>

<style lang="less" scoped>
.h5header {
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 44px;
    background: #000;
    z-index: 888;
    display: none;

    .return {
        position: absolute;
        top: 50%;
        left: 16px;
        width: 14px;
        height: 22px;
        transform: translateY(-50%);
    }

    .name {
        width: 80%;
        margin: 0 auto;
        font-size: 16px;
        color: #fff;
        height: 44px;
    }

    .lang {
        position: absolute;
        top: 50%;
        right: 16px;
        width: 24px;
        height: 24px;
        transform: translateY(-50%);

        img {
            width: 24px;
            height: 24px;
        }
    }

}

.lang-info {
    padding: 32px 16px;
    background: #181818;

    .lang-title {
        position: relative;
        font-size: 18px;
        color: #fff;
        text-align: center;
        margin-bottom: 20px;

        img {
            position: absolute;
            top: 4px;
            right: 0;
            width: 14px;
            height: 14px;
            cursor: pointer;
        }
    }

    .lang-item {
        padding: 10px 0;

        &:last-child {
            margin-bottom: 0;
        }

        div {
            flex: 1;
            font-size: 16px;
            color: #FFFFFF;
            line-height: 44px;
        }

        img {
            width: 16px;
            height: 16px;
            margin: 14px 0 0 0;

        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (max-width:767px) {
    .h5header {
        display: block;
    }
}</style>